.Container {
  background-color: rgb(159 136 102);
  /* width: 50%;
  margin-left: 25%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Container h1 {
  font-size: 1.5rem !important;
}
.card h2 {
  font-size: 1rem;
}
.item {
  align-self: center;
  padding: 2rem;
}
.card {
  /* position: absolute; */
  /* left: 50%; */
  /* width: 500px; */
  background-color: #169fd9;
  border: 1px solid silver;
  /* transform: translate(-50%); */
  outline: none;
  box-shadow: 300px;
  max-height: 80%;
  min-height: 40%;
}
.card h2 {
  text-align: center;
  border-bottom: 1px solid rgb(218, 214, 214);
  padding: 5px 0 10px 0;
}
.item button {
  background-color: aquamarine;
  border-radius: 50%;
  font-size: 1rem;
  cursor: pointer;
}
.item button:hover {
  background-color: cadetblue;
}
.flex-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}
.flex-item h4 {
  font-size: 0.9rem;
}
.flex-item2 button {
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  font-size: 1.3rem;
  text-align: center;
  width: 1.9rem;
  height: 1.6rem;
  cursor: pointer;
  line-height: 1px;
}
.flex-item2 button:hover {
  background-color: hsl(0, 61%, 52%);
  text-align: center;
}
.flex-item3 button {
  background-color: rgb(224, 226, 84);
  border-radius: 50%;
  font-size: 0.4rem;
  font-weight: 700;
  cursor: pointer;
  width: 2.4rem;
  height: 1.6rem;
}
.flex-item3 button:hover {
  background-color: hsl(59, 73%, 36%);
  text-align: center;
}
.btn {
  margin: auto;
}
.btn-List {
  display: inline-block;

  float: right;
  width: 50%;
}
.flex-item2 {
  width: 30%;
  float: right;
}
.flex-item1 {
  width: 50%;
  float: left;
  padding: 1rem;
}
.flex-item3 {
  width: 30%;
  float: right;
}
